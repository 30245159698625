import styles from "@scss/components/ValorantAccountShow.module.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
const serverName = { ap: "亚服", na: "美服", eu: "欧服", kr: "韩服" };
const intervals = [
  { label: "年", seconds: 31536000 },
  { label: "月", seconds: 2592000 },
  { label: "周", seconds: 604800 },
  { label: "天", seconds: 86400 },
  { label: "小时", seconds: 3600 },
  { label: "分钟", seconds: 60 },
  { label: "秒", seconds: 1 },
];

export default function ValorantAccountShow({ account, onDelete }) {
  const navigate = useNavigate();

  function handleNavigate() {
    window.open(`/valorant/${account.id}`, "_blank");
  }
  function timeAgo(date) {
    const now = new Date();
    const createdAt = new Date(date);
    const diffInSeconds = Math.floor((now - createdAt) / 1000);

    for (const interval of intervals) {
      const count = Math.floor(diffInSeconds / interval.seconds);
      if (count >= 1) {
        return `${count}${interval.label}前`;
      }
    }

    return "刚刚";
  }
  function copyId(event) {
    event.stopPropagation();
    navigator.clipboard.writeText(account.web_id);
  }

  return (
    <div className={styles["Account"]} onClick={handleNavigate}>
      <div className={styles["Account__Container-1"]}>
        <div className={styles["Account__Info-1"]}>
          <div className={styles["Account__detail"]}>
            {serverName[account.server]}
          </div>
          <div className={styles["Account__detail"]}>
            {account.email ? "带初邮" : "无初邮"}
          </div>
          <div
            className={`${styles["Account__detail"]} ${styles["Account__detail-level"]}`}
          >
            {account.level} LV
          </div>
          <div className={styles["Account__detail"]}>
            皮肤 {account.total_skin}
          </div>
        </div>
        <div className={styles["Account__Info-2"]}>
          <div className={styles["Account__Image"]}>
            <img
              src="/static/icon/shield.png"
              alt="shield"
              className={styles["Account__Img"]}
            />
          </div>
          <div className={styles["Account__point"]}></div>
          <div
            className={`${styles["Account__Image"]} ${styles["Account__Image-upload"]}`}
          >
            <img
              src="/static/icon/upload.png"
              alt="shield"
              className={styles["Account__Img"]}
            />
          </div>
          <div className={styles["Account__time"]}>
            {timeAgo(account.created_at)}
          </div>
          <div className={styles["Account__id"]}>
            {account.web_id ? account.web_id : account.id}
          </div>
          <div
            className={`${styles["Account__Image"]} ${styles["Account__Image-copy"]}`}
            onClick={copyId}
          >
            <img
              src="/static/icon/copy.png"
              alt="shield"
              className={styles["Account__Img-copy"]}
            />
          </div>
        </div>
      </div>
      <div className={styles["Account__Container-2"]}>
        <div className={styles["Account__price"]}>
          ￥{account.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
        <div className={styles["Account__RankImg"]}>
          <img src={`/static/rank/${account.rank}.png`} alt={account.rank} />
        </div>
      </div>
      <div className={styles["Account__Container-3"]}>
        <button
          className={`${styles["Account__Button"]} ${styles["Account__Button-save"]}`}
        >
          保存
        </button>
        <button
          className={`${styles["Account__Button"]} ${styles["Account__Button-delete"]}`}
          onClick={onDelete}
        >
          删除
        </button>
      </div>
    </div>
  );
}
