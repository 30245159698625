import styles from "@scss/pages/ValorantSelectPage/ValorantSelectPage.module.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const serverName = { ap: "亚服", na: "美服", eu: "欧服", kr: "韩服" };
const intervals = [
  { label: "年", seconds: 31536000 },
  { label: "月", seconds: 2592000 },
  { label: "周", seconds: 604800 },
  { label: "天", seconds: 86400 },
  { label: "小时", seconds: 3600 },
  { label: "分钟", seconds: 60 },
  { label: "秒", seconds: 1 },
];

export default function Accounts({
  accountData,
  sortAccountsByPrice,
  sortAccountsByDate,
}) {
  return (
    <div className={styles["Accounts"]}>
      <Filter
        sortAccountsByPrice={sortAccountsByPrice}
        sortAccountsByDate={sortAccountsByDate}
      />
      {/* <Account /> */}
      {accountData &&
        accountData.map((account) => <Account account={account} />)}
    </div>
  );
}

function Filter({ sortAccountsByPrice, sortAccountsByDate }) {
  const [dateFilter, setDateFilter] = useState("");
  const [priceFilter, setPriceFilter] = useState("");
  function handleSetDateFilter() {
    setPriceFilter("");
    if (dateFilter === "asc") {
      setDateFilter("desc");
      sortAccountsByDate("desc");
    } else {
      setDateFilter("asc");
      sortAccountsByDate("asc");
    }
  }
  function handleSetPriceFilter() {
    setDateFilter("");
    if (priceFilter === "asc") {
      setPriceFilter("desc");
      sortAccountsByPrice("desc");
    } else {
      setPriceFilter("asc");
      sortAccountsByPrice("asc");
    }
  }
  return (
    <div className={styles["Filter"]}>
      <div className={styles["Filter__Detail"]} onClick={handleSetDateFilter}>
        <div className={styles["Filter__Text"]}>日期</div>
        <div className={styles["Filter__Icon"]}>
          <img
            src="/static/icon/filterArrow.png"
            alt="filterArrow"
            className={`${styles["Filter__Arrow"]} ${
              styles["Filter__Arrow-Top"]
            } ${dateFilter === "desc" && styles["Filter__Arrow-NoSelect"]}`}
            draggable="false"
          />
          <img
            src="/static/icon/filterArrow.png"
            alt="filterArrow"
            className={`${styles["Filter__Arrow"]} ${
              styles["Filter__Arrow-Bottom"]
            } ${dateFilter === "asc" && styles["Filter__Arrow-NoSelect"]}`}
            draggable="false"
          />
        </div>
      </div>
      <div className={styles["Filter__Detail"]} onClick={handleSetPriceFilter}>
        <div className={styles["Filter__Text"]}>价格</div>
        <div className={styles["Filter__Icon"]}>
          <img
            src="/static/icon/filterArrow.png"
            alt="filterArrow"
            className={`${styles["Filter__Arrow"]} ${
              styles["Filter__Arrow-Top"]
            } ${priceFilter === "desc" && styles["Filter__Arrow-NoSelect"]}`}
            draggable="false"
          />
          <img
            src="/static/icon/filterArrow.png"
            alt="filterArrow"
            className={`${styles["Filter__Arrow"]} ${
              styles["Filter__Arrow-Bottom"]
            } ${priceFilter === "asc" && styles["Filter__Arrow-NoSelect"]}`}
            draggable="false"
          />
        </div>
      </div>
    </div>
  );
}

function Account({ account }) {
  const navigate = useNavigate();

  function handleNavigate() {
    window.open(`/valorant/${account.id}`, "_blank");
  }
  function timeAgo(date) {
    const now = new Date();
    const createdAt = new Date(date);
    const diffInSeconds = Math.floor((now - createdAt) / 1000);

    for (const interval of intervals) {
      const count = Math.floor(diffInSeconds / interval.seconds);
      if (count >= 1) {
        return `${count}${interval.label}前`;
      }
    }

    return "刚刚";
  }
  return (
    <div className={styles["Account"]} onClick={handleNavigate}>
      <div className={styles["Account__Container-1"]}>
        <div className={styles["Account__Info-1"]}>
          <div className={styles["Account__detail"]}>
            {serverName[account.server]}
          </div>
          <div className={styles["Account__detail"]}>
            {account.email ? "带初邮" : "无初邮"}
          </div>
          <div
            className={`${styles["Account__detail"]} ${styles["Account__detail-level"]}`}
          >
            {account.level} LV
          </div>
          <div className={styles["Account__detail"]}>
            皮肤 {account.total_skin}
          </div>
        </div>
        <div className={styles["Account__Info-2"]}>
          <div className={styles["Account__Image"]}>
            <img
              src="/static/icon/shield.png"
              alt="shield"
              className={styles["Account__Img"]}
            />
          </div>
          <div className={styles["Account__point"]}></div>
          <div
            className={`${styles["Account__Image"]} ${styles["Account__Image-upload"]}`}
          >
            <img
              src="/static/icon/upload.png"
              alt="shield"
              className={styles["Account__Img"]}
            />
          </div>
          <div className={styles["Account__time"]}>
            {timeAgo(account.created_at)}
          </div>
        </div>
      </div>
      <div className={styles["Account__Container-2"]}>
        <div className={styles["Account__price"]}>
          ￥{account.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
        <div className={styles["Account__RankImg"]}>
          <img src={`/static/rank/${account.rank}.png`} alt={account.rank} />
        </div>
      </div>
    </div>
  );
}

// function Account({ account }) {
//   const navigate = useNavigate();

//   function handleNavigate() {
//     window.open(`/valorant/${account.id}`, "_blank");
//   }
//   return (
//     <div className={styles["Account"]} onClick={handleNavigate}>
//       <div className={styles["Account__RankImg"]}>
//         <img src={`/static/rank/${account.rank}.png`} alt={account.rank} />
//       </div>
//       <div className={styles["Account__Info"]}>
//         <div className={styles["Account__InfoIcon"]}>
//           <img
//             className={styles["Account__InfoIconImg"]}
//             src="/static/icon/world.png"
//             alt="world icon"
//           />
//           <div className={styles["Account__InfoText"]}>
//             {serverName[account.server]}
//           </div>
//         </div>
//         <div className={styles["Account__InfoIcon"]}>
//           <img
//             className={styles["Account__InfoIconImg"]}
//             src="/static/icon/email.png"
//             alt="email icon"
//           />
//           <div className={styles["Account__InfoText"]}>
//             {account.email ? "带初邮" : "无初邮"}
//           </div>
//         </div>
//         <div className={styles["Account__InfoIcon"]}>
//           <img
//             className={styles["Account__InfoIconImg"]}
//             src="/static/icon/right.png"
//             alt="level icon"
//           />
//           <div className={styles["Account__InfoText"]}>{account.level} LV</div>
//         </div>
//       </div>
//       <div className={styles["Account__ItemNum"]}>
//         <div className={styles["Account__ItemNumSkin"]}>
//           <div className={styles["Account__ItemNumSkinTotal"]}>
//             皮肤 {account.total_skin}
//           </div>
//           <div className={styles["Account__ItemNumSkinContent"]}>
//             <div className={styles["Account__ItemNumSkinContentDetail"]}>
//               <img src="/static/skin_level/ultra.webp" alt="ultra" />
//               <div>{account.ultra}</div>
//             </div>
//             <div className={styles["Account__ItemNumSkinContentDetail"]}>
//               <img src="/static/skin_level/exclusive.webp" alt="exclusive" />
//               <div>{account.exclusive}</div>
//             </div>
//           </div>
//         </div>
//         <div className={styles["Account__ItemNumCurrency"]}>
//           <div className={styles["Account__ItemNumCurrencyDetail"]}>
//             <img src="/static/icon/vp.webp" alt="vp" />
//             <div className={styles["Account__Text"]}>{account.vp}</div>
//           </div>
//           <div className={styles["Account__ItemNumCurrencyDetail"]}>
//             <img src="/static/icon/radianite.webp" alt="radianite" />
//             <div className={styles["Account__Text"]}>{account.radianite}</div>
//           </div>
//           <div className={styles["Account__ItemNumCurrencyDetail"]}>
//             <img src="/static/icon/kc.webp" alt="kc" />
//             <div className={styles["Account__Text"]}>{account.kc}</div>
//           </div>
//         </div>
//       </div>
//       <div className={styles["Account__Contact"]}>
//         <div className={styles["Account__Price"]}>
//           ￥{account.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
//         </div>
//       </div>
//     </div>
//   );
// }
