import React, { useState, useEffect } from "react";
import styles from "@scss/pages/AnalyzePage/AnalyzePage.module.scss";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Cookies from "js-cookie";

export default function ValorantRecycle() {
  // 修改 allSkins 和 allCards 为包含 skinsname 和 uuid 的对象数组
  const allSkins = [
    { skinsname: "2021 Vandal", uuid: "9f51da57-4623-415a-6313-f49588297d0e" },
    {
      skinsname: "2021 Karambit",
      uuid: "470e2855-4b86-f62d-258f-4588056a7b23",
    },
    { skinsname: "2022 Phantom", uuid: "524b0c42-47da-e7ca-dc04-85ba285b2e71" },
    {
      skinsname: "2022 Butterfly Knife",
      uuid: "ba0abff4-41e4-4036-980d-90a55837b692",
    },
    { skinsname: "2023 Vandal", uuid: "69f94fda-4603-744a-87fe-2391be7462e8" },
    { skinsname: "2023 Kunai", uuid: "19c97db0-4ea8-d0de-d2a9-398eecb07298" },
    { skinsname: "2024 Phantom", uuid: "b37b99d4-4bbe-98c6-0168-3dafda0768e9" },
    { skinsname: "2024 Blade", uuid: "3f547576-41a5-9b71-76d5-9288bb031350" },
    { skinsname: "怜悯", uuid: "e98461ab-4e29-3bbe-513b-0b82f3013050" },
    { skinsname: "离火", uuid: "445de3d7-4833-5bf7-ef75-aeb4a0212229" },
  ];

  const allCards = [
    { skinsname: "Card1", uuid: "uuid1" },
    { skinsname: "Card2", uuid: "uuid2" },
    { skinsname: "Card3", uuid: "uuid3" },
  ];

  // 初始化可用的皮肤和卡片状态
  const [availableSkins, setAvailableSkins] = useState(allSkins);
  const [availableCards, setAvailableCards] = useState(allCards);
  const [selectedSkins, setSelectedSkins] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);
  const [initialFormData, setInitialFormData] = useState(null);

  // 当皮肤选项被拖拽到容器中时，从原始列表中移除
  const handleOptionDrop = (droppedOption) => {
    setAvailableSkins((prevOptions) =>
      prevOptions.filter((option) => option.uuid !== droppedOption.uuid)
    );
    setSelectedSkins((prevSelected) => [...prevSelected, droppedOption]);
  };

  // 当皮肤选项从容器中移除时，添加回原始列表
  const handleOptionRemove = (removedOption) => {
    setAvailableSkins((prevOptions) => [...prevOptions, removedOption]);
    setSelectedSkins((prevSelected) =>
      prevSelected.filter((option) => option.uuid !== removedOption.uuid)
    );
  };

  // 当卡面选项被拖拽到容器中时，从原始列表中移除
  const handleCardOptionDrop = (droppedOption) => {
    setAvailableCards((prevOptions) =>
      prevOptions.filter((option) => option.uuid !== droppedOption.uuid)
    );
    setSelectedCards((prevSelected) => [...prevSelected, droppedOption]);
  };

  // 当卡面选项从容器中移除时，添加回原始列表
  const handleCardOptionRemove = (removedOption) => {
    setAvailableCards((prevOptions) => [...prevOptions, removedOption]);
    setSelectedCards((prevSelected) =>
      prevSelected.filter((option) => option.uuid !== removedOption.uuid)
    );
  };

  useEffect(() => {
    const token = Cookies.get("access_token");

    fetch(`${process.env.REACT_APP_API_URL}/valorantSellSettingData/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // 设置已选中的皮肤和卡面
        const selectedSkinsData = data.valorant_sell_items
          .filter((item) => item.itemType === "skin")
          .map((item) => ({
            skinsname: item.name,
            uuid: item.uuid,
          }));

        const selectedCardsData = data.valorant_sell_items
          .filter((item) => item.itemType === "card")
          .map((item) => ({
            skinsname: item.name,
            uuid: item.uuid,
          }));

        setSelectedSkins(selectedSkinsData);
        setSelectedCards(selectedCardsData);

        // 设置可用的皮肤和卡面
        setAvailableSkins(
          allSkins.filter(
            (skin) => !selectedSkinsData.some((s) => s.uuid === skin.uuid)
          )
        );
        setAvailableCards(
          allCards.filter(
            (card) => !selectedCardsData.some((c) => c.uuid === card.uuid)
          )
        );

        // 设置初始表单数据
        const initialData = {
          proportion: data.valorant_sell[0].proportion,
          lowestPrice: data.valorant_sell[0].lowestPrice,
          highestPrice: data.valorant_sell[0].highestPrice,
          lastActive: data.valorant_sell[0].lastActive,
          totalLimit: data.valorant_sell[0].totalLimit,
          selectedRegions: [],
          selectedSkinType: data.valorant_sell[0].allSkin,
          selectedCardType: data.valorant_sell[0].allCard,
          skinOptions: selectedSkinsData,
          cardOptions: selectedCardsData,
        };

        if (data.valorant_sell[0].naServer)
          initialData.selectedRegions.push("NA");
        if (data.valorant_sell[0].euServer)
          initialData.selectedRegions.push("EU");
        if (data.valorant_sell[0].apServer)
          initialData.selectedRegions.push("AP");
        if (data.valorant_sell[0].krServer)
          initialData.selectedRegions.push("KR");

        setInitialFormData(initialData);
      })
      .catch((error) => {
        // 处理错误
        console.error("Error:", error);
      });
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={styles["ValorantRecycle"]}>
        <div className={styles["ValorantRecycle__formContainer"]}>
          {initialFormData && (
            <Form
              onOptionDrop={handleOptionDrop}
              onOptionRemove={handleOptionRemove}
              onCardOptionDrop={handleCardOptionDrop}
              onCardOptionRemove={handleCardOptionRemove}
              initialFormData={initialFormData}
              selectedSkins={selectedSkins}
              selectedCards={selectedCards}
              setSelectedSkins={setSelectedSkins}
              setSelectedCards={setSelectedCards}
            />
          )}
        </div>
        <ItemContainer
          availableSkins={availableSkins}
          availableCards={availableCards}
        />
      </div>
    </DndProvider>
  );
}

function Form({
  onOptionDrop,
  onOptionRemove,
  onCardOptionDrop,
  onCardOptionRemove,
  initialFormData,
  selectedSkins,
  selectedCards,
  setSelectedSkins,
  setSelectedCards,
}) {
  const [selectedRegions, setSelectedRegions] = useState(
    initialFormData.selectedRegions
  );
  const [selectedSkinType, setSelectedSkinType] = useState(
    initialFormData.selectedSkinType
  );
  const [selectedCardType, setSelectedCardType] = useState(
    initialFormData.selectedCardType
  );
  const regions = ["NA", "EU", "AP", "KR"];
  const [proportion, setPropotion] = useState(initialFormData.proportion);
  const [lowestPrice, setLowestPrice] = useState(initialFormData.lowestPrice);
  const [highestPrice, setHighestPrice] = useState(
    initialFormData.highestPrice
  );
  const [lastActive, setLastActive] = useState(initialFormData.lastActive);
  const [totalLimit, setTotalLimit] = useState(initialFormData.totalLimit);
  const [skinOptions, setSkinOptions] = useState(selectedSkins);
  const [cardOptions, setCardOptions] = useState(selectedCards);

  const toggleRegion = (region) => {
    setSelectedRegions((prev) =>
      prev.includes(region)
        ? prev.filter((r) => r !== region)
        : [...prev, region]
    );
  };

  function toggleSkin() {
    setSelectedSkinType((prev) => !prev);
  }

  function toggleCard() {
    setSelectedCardType((prev) => !prev);
  }

  function handleSubmit(e) {
    e.preventDefault(); // 阻止默认的表单提交行为
    // 收集表单数据，提取 uuid 发送到后端
    const formData = {
      selectedRegions,
      selectedSkinType,
      selectedCardType,
      proportion,
      lowestPrice,
      highestPrice,
      lastActive,
      totalLimit,
      skinOptions: skinOptions,
      cardOptions: cardOptions,
    };

    // 将数据发送到后台
    const token = Cookies.get("access_token");

    fetch(`${process.env.REACT_APP_API_URL}/valorantSellUpload/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        // 处理成功响应
        console.log("Success:", data);
      })
      .catch((error) => {
        // 处理错误
        console.error("Error:", error);
      });
  }

  // 重置表单数据
  function handleReset() {
    // 重置所有的状态变量
    setSelectedRegions([]);
    setSelectedSkinType(true);
    setSelectedCardType(true);
    setPropotion(10);
    setLowestPrice(0);
    setHighestPrice(100);
    setLastActive(0);
    setTotalLimit(0);
    setSkinOptions([]); // 重置皮肤选项
    setCardOptions([]); // 重置卡面选项
    setSelectedSkins([]); // 同步父组件的状态
    setSelectedCards([]);
  }

  return (
    <form onSubmit={handleSubmit} className={styles["ValorantRecycle__form"]}>
      <BasicSetting
        proportion={proportion}
        setPropotion={setPropotion}
        lowestPrice={lowestPrice}
        setLowestPrice={setLowestPrice}
        highestPrice={highestPrice}
        setHighestPrice={setHighestPrice}
        regions={regions}
        selectedRegions={selectedRegions}
        toggleRegion={toggleRegion}
        lastActive={lastActive}
        setLastActive={setLastActive}
        totalLimit={totalLimit}
        setTotalLimit={setTotalLimit}
      />
      <SkinOptionContainer
        toggleSkin={toggleSkin}
        selectedSkinType={selectedSkinType}
        onOptionDrop={onOptionDrop}
        onOptionRemove={onOptionRemove}
        skinOptions={skinOptions}
        setSkinOptions={setSkinOptions}
      />
      <CardOptionContainer
        toggleCard={toggleCard}
        selectedCardType={selectedCardType}
        onCardOptionDrop={onCardOptionDrop}
        onCardOptionRemove={onCardOptionRemove}
        cardOptions={cardOptions}
        setCardOptions={setCardOptions}
      />
      <div className={styles["ValorantRecycle__SaveContainer"]}>
        <button
          type="submit"
          className={`${styles["ValorantRecycle__Button"]} ${styles["ValorantRecycle__Button-blue"]}`}
        >
          保存
        </button>
        <button
          type="button"
          onClick={handleReset}
          className={`${styles["ValorantRecycle__Button"]} ${styles["ValorantRecycle__Button-red"]}`}
        >
          清除
        </button>
      </div>
    </form>
  );
}

function BasicSetting({
  proportion,
  setPropotion,
  lowestPrice,
  setLowestPrice,
  highestPrice,
  setHighestPrice,
  regions,
  selectedRegions,
  toggleRegion,
  lastActive,
  setLastActive,
  totalLimit,
  setTotalLimit,
}) {
  return (
    <>
      {" "}
      <div className={styles["ValorantRecycle__priceContainer"]}>
        <div className={styles["ValorantRecycle__ValueContainer"]}>
          <input
            className={styles["ValorantRecycle__priceRecycle"]}
            type="text"
            id="priceInput"
            name="priceInput"
            placeholder="账号回收比例"
            value={proportion}
            onChange={(e) => setPropotion(Number(e.target.value))}
          />
        </div>
        <div className={styles["ValorantRecycle__SetPriceContainer"]}>
          <input
            className={styles["ValorantRecycle__priceNum"]}
            type="text"
            id="priceInputLow"
            name="priceInputLow"
            placeholder="低"
            value={lowestPrice}
            onChange={(e) => setLowestPrice(Number(e.target.value))}
          />
          <div className={styles["ValorantRecycle__line"]}></div>
          <input
            className={styles["ValorantRecycle__priceNum"]}
            type="text"
            id="priceInputHigh"
            name="priceInputHigh"
            placeholder="高"
            value={highestPrice}
            onChange={(e) => setHighestPrice(Number(e.target.value))}
          />
        </div>
      </div>
      <div className={styles["ValorantRecycle__ServerContainer"]}>
        {regions.map((region) => (
          <button
            key={region}
            type="button"
            className={`${styles["ValorantRecycle__ServerButton"]}  ${
              selectedRegions.includes(region) ? styles["selected"] : ""
            }`}
            onClick={() => toggleRegion(region)}
          >
            {region}
          </button>
        ))}
      </div>
      <div className={styles["ValorantRecycle__ActiveContainer"]}>
        <div className={styles["ValorantRecycle__ValueContainer"]}>
          <input
            className={styles["ValorantRecycle__priceRecycle"]}
            type="text"
            id="lastActiveInput"
            name="lastActiveInput"
            placeholder="最后激活时间"
            value={lastActive === 0 ? "" : lastActive}
            onChange={(e) => setLastActive(Number(e.target.value))}
          />
        </div>
        <div className={styles["ValorantRecycle__ValueContainer"]}>
          <input
            className={styles["ValorantRecycle__priceRecycle"]}
            type="text"
            id="lastActiveInput"
            name="lastActiveInput"
            placeholder="回收数量"
            value={totalLimit === 0 ? "" : totalLimit}
            onChange={(e) => setTotalLimit(Number(e.target.value))}
          />
        </div>
      </div>
    </>
  );
}

function SkinOptionContainer({
  toggleSkin,
  selectedSkinType,
  onOptionDrop,
  onOptionRemove,
  skinOptions,
  setSkinOptions,
}) {
  return (
    <>
      {" "}
      <div
        className={`${styles["ValorantRecycle__OptionContainer"]} ${styles["ValorantRecycle__OptionContainer-heigh1"]}`}
      >
        <div className={styles["ValorantRecycle__Title"]}>
          <div className={styles["ValorantRecycle__TitleText"]}>皮肤</div>
          <button
            type="button"
            onClick={() => toggleSkin()}
            className={`${styles["ValorantRecycle__SkinButton"]} ${
              selectedSkinType ? styles["select"] : ""
            }`}
          >
            全部
          </button>
        </div>
        <OptionContainer
          acceptType="SKIN_OPTION"
          onOptionDrop={onOptionDrop}
          onOptionRemove={onOptionRemove}
          droppedOptions={skinOptions}
          setDroppedOptions={setSkinOptions}
        />
      </div>
    </>
  );
}

function ItemContainer({ availableSkins, availableCards }) {
  return (
    <div className={styles["ValorantRecycle__ItemContainer"]}>
      <div className={styles["ValorantRecycle__SkinContainer"]}>
        <div className={styles["ValorantRecycle__OptionTitle"]}>皮肤</div>
        {availableSkins.map((option) => (
          <DraggableOption
            key={option.uuid}
            option={option}
            type="SKIN_OPTION"
          />
        ))}
      </div>
      <div className={styles["ValorantRecycle__CardContainer"]}>
        <div className={styles["ValorantRecycle__OptionTitle"]}>卡面</div>
        {availableCards.map((option) => (
          <DraggableOption
            key={option.uuid}
            option={option}
            type="CARD_OPTION"
          />
        ))}
      </div>
    </div>
  );
}

function CardOptionContainer({
  toggleCard,
  selectedCardType,
  onCardOptionDrop,
  onCardOptionRemove,
  cardOptions,
  setCardOptions,
}) {
  return (
    <>
      <div
        className={`${styles["ValorantRecycle__OptionContainer"]} ${styles["ValorantRecycle__OptionContainer-heigh2"]}`}
      >
        <div className={styles["ValorantRecycle__Title"]}>
          <div className={styles["ValorantRecycle__TitleText"]}>卡面</div>
          <button
            type="button"
            onClick={() => toggleCard()}
            className={`${styles["ValorantRecycle__SkinButton"]} ${
              selectedCardType ? styles["select"] : ""
            }`}
          >
            全部
          </button>
        </div>
        <OptionContainer
          acceptType="CARD_OPTION"
          onOptionDrop={onCardOptionDrop}
          onOptionRemove={onCardOptionRemove}
          droppedOptions={cardOptions}
          setDroppedOptions={setCardOptions}
        />
      </div>
    </>
  );
}

const OptionContainer = ({
  onOptionDrop,
  onOptionRemove,
  acceptType,
  droppedOptions,
  setDroppedOptions,
}) => {
  const [{ isOver }, drop] = useDrop({
    accept: acceptType,
    drop: (item) => {
      setDroppedOptions((prevOptions) => [...prevOptions, item.option]);
      if (onOptionDrop) {
        onOptionDrop(item.option);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const handleOptionClick = (option) => {
    setDroppedOptions((prevOptions) =>
      prevOptions.filter((opt) => opt.uuid !== option.uuid)
    );
    if (onOptionRemove) {
      onOptionRemove(option);
    }
  };

  return (
    <div ref={drop} className={styles["OptionContainer"]}>
      {droppedOptions.map((option, index) => (
        <div
          key={index}
          className={styles["ValorantRecycle__Option"]}
          onClick={() => handleOptionClick(option)}
        >
          {option.skinsname}
        </div>
      ))}
    </div>
  );
};

const DraggableOption = ({ option, type }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: type,
    item: { option },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      className={styles["ValorantRecycle__Option"]}
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      {option.skinsname}
    </div>
  );
};
